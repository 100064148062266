<template>
  <!-- 质检类型 -->
  <div class="controller-type">
    <!-- 筛选区域 -->
    <div class="screening">
      <!--      <el-collapse v-model="activeNames">-->
      <!--        <el-collapse-item title="筛选框" name="1">-->
      <!--          <div class="screening-out">-->
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <!-- 筛选项 -->
        <el-row :gutter="12">
          <el-col :span="20">
            <el-form-item label="类型名称：" prop="">
              <el-input v-model="searchForm.name" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="关键字串：" prop="">
              <el-input v-model="searchForm.keywords" clearable size="mini"></el-input>
            </el-form-item>
            <!--            <el-form-item label="默认规则：" prop="">-->
            <!--              <el-select v-model="searchForm.isDefault" placeholder="请选择" style="width: 100%" clearable-->
            <!--                         size="mini">-->
            <!--                <el-option v-for="(item, index) in ['非默认', '默认']" :key="index" :label="item"-->
            <!--                           :value="index">-->
            <!--                </el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->
            <el-form-item label="状&emsp;态&emsp;：" prop="">
              <el-select v-model="searchForm.status" placeholder="请选择" style="width: 100%" clearable
                         size="mini">
                <el-option v-for="(item, index) in ['正常', '禁用']" :key="index" :label="item"
                           :value="index">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="风险等级：" prop="">
              <el-select v-model="searchForm.risk" placeholder="请选择" style="width: 100%" clearable
                         size="mini">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <div class="m3-flex m3-justify-content-center">
              <el-button class="cancel" size="small" @click="searchTapReset()">重置</el-button>
              <el-button type="primary" size="small" class="confirmAdd" @click="searchListFun()">查询
              </el-button>
            </div>
          </el-col>

        </el-row>
      </el-form>
      <!--          </div>-->
      <!--        </el-collapse-item>-->
      <!--      </el-collapse>-->
    </div>
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name">
          <span>类型列表</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
          <a-button v-if="!isAdminUser" style="margin-right: 8px" type="primary" @click="addSon">添加类型</a-button>
          <a-button type="primary" @click="searchTapReset">
            <icon-font type="icon-shuaxin" class="md-body-medium"/>
            刷新
          </a-button>
          <!--          <el-tooltip content="添加类型" placement="top" effect="light">-->
          <!--            <el-button class="editor" size="mini" icon="el-icon-plus" @click="addSon" circle></el-button>-->
          <!--          </el-tooltip>-->
          <!--          <div class="ml-10">-->
          <!--            <el-tooltip content="刷新列表" placement="top" effect="light">-->
          <!--              <div class="font-refresh-out flex-nowrap-center-center" @click="searchTapReset">-->
          <!--                <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh"/>-->
          <!--                <i v-else class="el-icon-loading loding-color"></i>-->
          <!--              </div>-->
          <!--            </el-tooltip>-->
          <!--          </div>-->
        </div>
      </el-col>
    </el-row>
    <!-- 数据展示 -->
    <el-row>
      <el-col>
        <div style="overflow-x: auto">
          <el-table v-loading="loading" ref="multipleTable" :data="tableData" size="mini" fit
                    :row-style="{height:'50px'}" :cell-style="{padding:'0px'}" tooltip-effect="dark" style="width: 100%"
                    :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }">
            <el-table-column label="类型" width="160">
              <template #default="scope">{{
                  scope.row.name == null ? "-" : scope.row.name
                }}
              </template>
            </el-table-column>
            <el-table-column label="关键字">
              <template #default="scope">
                <div class="m3-line-clamp-3">
                  {{ scope.row.keywords == null ? "-" : scope.row.keywords }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="100">
              <template #default="scope">
                 <span :class="rowStatusClass(scope.row)"
                       class="m3-padding-vertical-xs m3-padding-horizontal-m">
                    {{ scope.row.status == 1 ? "禁用" : '正常' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="风险等级" width="140">
              <template #default="scope">
                <span :class="rowRiskClass(scope.row)"
                      class="m3-padding-vertical-xs m3-padding-horizontal-m">
                   {{ rowRiskDesc(scope.row) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="创建/更新" width="200">
              <template #default="scope">
                <div class="grid">
                  <p>创建：{{ scope.row.createTime === null ? "-" : scope.row.createTime }}</p>
                  <p>更新：{{ scope.row.updateTime === null ? "-" : scope.row.updateTime }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="!isAdminUser" label="操作" width="150" fixed="right">
              <template #default="{row}">
                <a-button size="small" style="margin-right: 8px" type="primary" @click="typeModify(row)">编辑</a-button>
                <a-popconfirm
                    title="确定删除此条数据?"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="handelConfirm(row)"
                >
                  <a-button size="small" type="primary" danger>删除</a-button>
                </a-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <div class="pager">
      <div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                       background :page-sizes="[10, 20, 30, 40]" :page-size="searchForm.pageSize"
                       layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 添加质检类型 -->
    <a-modal width="690px" v-model:visible="centerAddtoVisible" title="添加质检规则" @cancel="handleCancel">
      <div class="add-from-s">
        <a-form layout="horizontal" :model="formCreateUpdate" :rules="rules" ref="formRef">
          <a-form-item has-feedback label="质检类型" name="name">
            <a-input v-model:value="formCreateUpdate.name" placeholder="请输入质检类型"></a-input>
          </a-form-item>
          <a-form-item has-feedback label="风险等级" name="risk">
            <a-radio-group v-model:value="formCreateUpdate.risk" button-style="solid">
              <a-radio-button value="3">高</a-radio-button>
              <a-radio-button value="2">中</a-radio-button>
              <a-radio-button value="1">低</a-radio-button>
              <a-radio-button value="0">无</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item has-feedback label="状态" name="status">
            <a-radio-group v-model:value="formCreateUpdate.status">
              <a-radio :value="0">正常</a-radio>
              <a-radio :value="1">禁用</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item has-feedback label="微信通知" name="noticeWx">
            <a-radio-group v-model:value="formCreateUpdate.noticeWx">
              <a-radio :value="1">开启</a-radio>
              <a-radio :value="0">关闭</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item has-feedback label="敏感词" name="keyword">
            <a-input v-model:value="formCreateUpdate.keyword" placeholder="请输入敏感词后回车"
                     @pressEnter="handelPressEnter"/>
          </a-form-item>
          <a-form-item label="关键词设置规则：">
            <div>
              <p>1、单个关键词输入后，按回车键保存（装修）+ 回车键 </p>
              <p>2、多个关键词请使用英文状态下“,”分割（贷款,利率,装修）+ 回车键</p>
              <p>3、组合关键词请使用“&”分割（贷款&利率&装修,教育&培训&幼儿）</p>
            </div>
            <div style="height: 200px;overflow: auto;border: 1px solid #e4e4e4;padding: 10px">
              <a-tag style="margin-bottom: 10px" @close="handleClose(item)" closable
                     v-for="(item, index) in formCreateUpdate.keywords" :key="index">{{ item }}
              </a-tag>
              <a-tag v-if="formCreateUpdate.keywords&&formCreateUpdate.keywords.length>0"
                     style="margin-bottom: 10px;margin-right: 10px;"
                     color="processing"
                     @click="copyKeywords"
                     key="copy">复制
              </a-tag>
            </div>
          </a-form-item>
        </a-form>
      </div>
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="btnLoading" @click="handelSave">保存</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import voice from '@/api/open/voice/voiceType'
import {message} from 'ant-design-vue'
import {IconFont} from '@/utils/iconfont'
import {ElMessageBox} from 'element-plus'
import {ElMessage} from 'element-plus'
import store from '@/store'
import {getInfo} from '@/api/web/user'
import {ref, toRaw, reactive} from 'vue'
import _lineService from '@/api/open/LineQualityInspections'
import {PageModule} from '../common/pagination'
import {copy} from "@/utils/common";
import {safePromise} from "@/utils/common/safePromise";
import EmptyUtil from "@/utils/common/EmptyUtil";

class CreateUpdateModule {
  constructor() {
    this.name = undefined
    this.risk = ''
    this.keywords = []
    this.status = '0'
    this.uid = ''
    this.keyword = ''
    this.noticeWx = 1
  }

  getCreateObj() {
    return {
      name: this.name,
      risk: parseInt(this.risk),
      keywords: this.keywords.join(','),
      status: this.status,
      uid: this.uid,
      noticeWx: this.noticeWx,
    }
  }

  getUpdateObj(id) {
    return {
      name: this.name,
      risk: parseInt(this.risk),
      keywords: this.keywords.join(','),
      id: id,
      status: this.status,
      uid: this.uid,
      noticeWx: this.noticeWx,
    }
  }

  setObjItem(item) {
    this.name = item.name
    this.risk = '' + item.risk
    this.keywords = (Array.isArray(item?.keywords) ? item.keywords : item.keywords?.split(',')) || ''
    this.status = item.status
    this.uid = item.uid
    this.noticeWx = item.noticeWx
  }

  addKeywords() {
    if (!this.keyword) {
      return;
    }
    if (!this.keywords) {
      this.keywords = [];
    }
    if (this.keyword.indexOf(',') !== -1) {
      let keywordsSplit = this.keyword
          .split(',')
          .map(item => item.trim())
          .filter(item => item && item.length > 0)
          .filter(item => !this.keywords.find(keyword => keyword == item));
      this.keywords = this.keywords.concat(keywordsSplit);
    } else {
      if (!!this.keyword.trim() && !this.keywords.find(item => item == this.keyword.trim())) {
        this.keywords.push(this.keyword.trim())
      }
    }
    //
    // if (this.keyword.indexOf(',') !== -1) {
    //   this.keywords = this.keywords.concat(
    //       this.keyword.split(',').map((item) => item.split(' ').join(''))
    //   )
    // } else {
    //   if (!!this.keyword) {
    //     this.keywords.push(this.keyword.split(' ').join(''))
    //   }
    // }
    this.keyword = ''
  }

  delKeywords(item) {
    // this.keywords.splice(index, 1)
    let findIndex = this.keywords.findIndex(keyword => keyword == item);
    if (findIndex != -1) {
      this.keywords.splice(findIndex, 1)
    }
  }
}

export default {
  name: 'ControllerType',
  components: {
    IconFont,
  },
  data() {
    return {
      rowId: '',
      formCreateUpdate: reactive(new CreateUpdateModule()),
      btnLoading: false,
      options: [
        {
          value: null,
          label: '全部',
        },
        {
          value: 3,
          label: '高',
          labelClass: 'risk-high-more'
        },
        {
          value: 2,
          label: '中',
          labelClass: 'risk-mid'
        },
        {
          value: 1,
          label: '低',
          labelClass: 'risk-low'

        },
        {
          value: 0,
          label: '无',
          labelClass: 'risk-none'
        },
      ],

      userType: store.state.userType,
      tableData: [], //当前页面数据
      activeNames: [], //折叠面板
      centerAddtoVisible: false, // 新增/修改弹框
      loading: true, // loding 状态启用
      tableHeight: '', //表格高度
      // operationType: 0, //弹窗提交按钮文字状态
      currentPage: 1, //分页
      total: 0, //总数据条数
      valueTimeCreate: [], //选中时间段
      searchForm: {
        // 搜索项
        name: '', // 类型名称
        isDefault: '', // 默认选项(0:非默认 1:默认)
        status: '', // 状态(0:启用 1:禁用)
        keywords: '', // 关键字
        pageSize: 10,
        page: 1,
        risk: '',
      },

      rules: {
        name: [{required: true, message: '请输入质检类型', trigger: 'blur'}],
        risk: [
          {required: true, message: '请选择风险等级', trigger: 'change'},
        ],
        status: [
          {
            required: true,
            type: 'number',
            message: '请选择状态',
            trigger: 'blur',
          },
        ],
        noticeWx: [
          {
            required: true,
            type: 'number',
            message: '请选择微信通知开启状态',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  mounted() {
    // if (this.userType == 'web') {
    //   this.getUserIndustry()
    // }
    this.queryList()
  },
  computed: {
    isAdminUser() {
      return this.userType == 'system';
    },
    rowRiskClass() {
      return row => {
        let res = '';
        let find = this.options.find(item => item.value == row.risk);
        res = find?.labelClass || '';
        return res;
      }
    },
    rowRiskDesc() {
      return row => {
        let res = '';
        let find = this.options.find(item => item.value == row.risk);
        res = find?.label || '';
        return res;
      }
    },
    rowStatusClass() {
      return row => {
        let res = row.status ? 'risk-high-more' : '';
        return res;
      }
    },
  },
  methods: {
    async handelConfirm(row) {
      // await _lineService._ruleService.deletes({id: row.id})
      const res = await safePromise(_lineService._vosService.deletes({id: row.id}));
      if (res?.code == 200){
        let findIndex = this.tableData.findIndex(item => item.id == row.id);
        if (findIndex !== -1) {
          this.tableData.splice(findIndex, 1);
        }
        this.$message.success('删除成功')
        // this.queryList()
      }
    },
    typeModify(row) {
      this.rowId = row.id
      this.centerAddtoVisible = true
      this.formCreateUpdate.setObjItem(row)
    },

    async handelSave() {
      this.btnLoading = true
      try {
        const obj = toRaw(this.formCreateUpdate)
        if (EmptyUtil.isEmpty(obj.uid)) {
          obj.uid = this.$store.state.user.uid;
        }
        await this.$refs.formRef.validate()
        if (this.rowId) {
          // await _lineService._ruleService.update(obj.getUpdateObj(this.rowId))
          await _lineService._vosService.update(obj.getUpdateObj(this.rowId));
        } else {
          // await _lineService._ruleService.created(obj.getCreateObj())
          await _lineService._vosService.created(obj.getCreateObj())
        }
        this.rowId = ''
        this.$message.success('保存成功')
        this.formCreateUpdate = new CreateUpdateModule()
        ref(this.$refs.formRef).value.resetFields()
        this.queryList()
        this.$nextTick(() => {
          this.centerAddtoVisible = false
        })
      } catch (err) {
        if (err) {
          this.$message.error(err.message)
        }
      }
      this.btnLoading = false
    },
    handleClose(item) {
      this.formCreateUpdate.delKeywords(item)
    },
    copyKeywords() {
      if (this.formCreateUpdate.keywords) {
        let copyText = this.formCreateUpdate.keywords.join(',');
        copy(copyText);
      }
    },
    handelPressEnter() {
      this.formCreateUpdate.addKeywords()
    },
    handleCancel() {
      this.rowId = ''
      this.formCreateUpdate = new CreateUpdateModule()
      ref(this.$refs.formRef).value.resetFields()
      this.$nextTick(() => {
        this.centerAddtoVisible = false
      })
    },
    // 条件筛选--重置
    searchTapReset() {
      this.searchForm = {
        page: this.currentPage,
        pageSize: 10,
      }
      this.queryList()
    },
    // 条件筛选--搜索
    searchListFun() {
      this.queryList()
    },

    // 分页--条数
    handleSizeChange(val) {
      this.searchForm.pageSize = val
      this.queryList()
    },
    // 分页--页码
    handleCurrentChange(val) {
      this.searchForm.page = val
      this.currentPage = val
      this.queryList()
    },
    // 添加子项--点击
    addSon() {
      this.centerAddtoVisible = true
    },
    // 查询数据列表
    async queryList() {
      this.loading = true
      // voice.voiceList({...this.searchForm}).then((res) => {
      let res = await safePromise(_lineService._vosService.getList({...this.searchForm}));
      // console.log('queryList ', res);
      this.loading = false;
      if (res?.data?.records) {
        let uid = this.$store.state.user.uid;
        res?.data?.records.forEach(item => {
          item.uid = uid;
        })
      }
      this.total = res?.data?.total || 0;
      this.tableData = res?.data?.records || [];
    },
    //用户行业下拉
    async getUserIndustry() {
      let res = await getInfo({})
      try {
        if (res.code !== 200) {
          message.error(res.message)
        } else {
          let result = await voice.userIndustry({uid: res.data.uid})
          if (result.code !== 200) {
            message.error(result.message)
          } else {
            this.industryList = result.data
          }
        }
      } catch (error) {
        console.log('error :>> ', error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.controller-type {
  padding-right: 0.9375rem;
}

.title-buttom {
  height: 40px;
}

.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}

.confirmAdd {
  background: #637dff;
  color: #fff;
}

.showKeywords {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>